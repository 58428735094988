import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { login, logout, register, sendLinkResetPassword, resetPassword } from "../api";

export const useLogin = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: ['useLoginMutate'],
    mutationFn: login,
    onSuccess: (status, variables, context ) => {
      return status;
    },
  });
  return query;
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: ['useLogoutMutate'],
    mutationFn: logout,
    onSuccess: (status, variables, context ) => {
      return status;
    },
  });
  return query;
};

export const useRegister = () => {
  const query = useMutation({
    mutationKey: ['useRegisterMutate'],
    mutationFn: register,
    onSuccess: (status, variables, context ) => {
      return status;
    },
  });
  return query;
};

export const useSendLinkResetPassword = () => {
  const query = useMutation({
    mutationKey: ['useSendLinkResetPasswordMutate'],
    mutationFn: sendLinkResetPassword,
    onSuccess: (status, variables, context ) => {
      return status;
    },
  });
  return query;
};

export const useResetPassword = () => {
  const query = useMutation({
    mutationKey: ['useResetPasswordMutate'],
    mutationFn: resetPassword,
    onSuccess: (status, variables, context ) => {
      return status;
    },
  });
  return query;
};