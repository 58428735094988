import { useQuery } from "@tanstack/react-query";
import { getSettings } from "../api";


export const useSetting = () => {
  const query = useQuery({
    queryKey: ['useSetting'],
    queryFn: () => getSettings(),
    staleTime: 1000 * 60 * 10,
    retry: false,
  });

  return query;
};