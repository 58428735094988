import { create } from "zustand";

export const useDomainStore = create((set) => ({
  domain: '',
  apiUrl: '',
  setUrlApi: (data) => {
    set((state) => {
      state.apiUrl = data;
      return { ...state };
    });
  },
  setDomain: (data) => {
    set((state) => {
      state.domain = data;
      return { ...state };
    });
  },
}));