import React from 'react';
import { Link } from 'react-router-dom';

import { FaCartShopping } from 'react-icons/fa6'

import { useProductStore } from "@/_zustand/store";

export const CartElement = () => {
  const { allQuantity } = useProductStore();
  
  return (
    <div className="relative">
      {
        allQuantity == 0 ? (
          <>
            <FaCartShopping className="text-2xl text-black" />
            <span className=" w-6 h-6 bg-blue-600 text-white rounded-full flex justify-center items-center absolute top-[-17px] right-[-22px]">
              { allQuantity }
            </span>
          </>
        ) : (
          <Link to="/cart">
            <FaCartShopping className="text-2xl text-black" />
            <span className=" w-6 h-6 bg-blue-600 text-white rounded-full flex justify-center items-center absolute top-[-17px] right-[-22px]">
              { allQuantity }
            </span>
          </Link>
        )
      }
      
    </div>
  )
}
