import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useSettingStore = create()(
  persist(
    (set) => ({
      setting: {},
      setSetting: (data) => {
        set((state) => {
          return {
            setting: data,
          };
        });
      },
    }),
    {
      name: "settings-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
