import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as _ from "lodash";

import { Footer } from "@/layouts/layoutcomponents/Footer";
import { Header } from '@/layouts/layoutcomponents/Header';

import { useCheckAuth } from '@/hooks/useCheckAuth';
import { useSettingStore } from '@/_zustand/settingsStore';
import { useSetting } from '@/hooks/useSettings';

export const App = () => {
  const { pathname } = useLocation();
  const status = useCheckAuth();
  const setting = useSetting();
  const { setSetting } = useSettingStore();

  useEffect(() => {
    if(_.size(setting.data) > 0) {
      setSetting(setting.data);
      console.log(setting.data);
    }
  }, [setting.data]);

  useEffect(() => {
    console.log(status);
  }, [pathname]);

  return (
    <Fragment>
      <Header />
      <Outlet />
      <Footer />
    </Fragment>
  )
}
