import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getWishlist, getAllWishlistByUserId, getSingleProductFromWishlist, createWishlist, deleteWishlist } from "../api";


export const useWishlistList = () => {
  const query = useQuery({
    queryKey: ['wishlist'],
    queryFn:  getWishlist(),
    staleTime: 1000 * 60 * 60,
    retry: false,
  });

  return query;
};

export const useWishlistByUserId = () => {
  const query = useMutation({
    mutationKey: ['useWishlistByUserIdMutate'],
    mutationFn: getAllWishlistByUserId,
    onSuccess: (status, variables, context ) => {
      return status;
    },
  });

  return query;
};

export const useSingleProductFromWishlist = () => {
  const query = useMutation({
    mutationKey: ['useSingleProductFromWishlist'],
    mutationFn: getSingleProductFromWishlist,
    onSuccess: (status) => {
      return status;
    },
  });

  return query;
};

export const useWishlistCreate = () => {
  const query = useMutation({
    mutationKey: ['useWishlistCreate'],
    mutationFn: createWishlist,
    onSuccess: (status) => {
      return status;
    },
  });

  return query;
};

export const useWishlistDelete = () => {
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationKey: ['useWishlistDelete'],
    mutationFn: deleteWishlist,
    onSuccess: (status, variables, context) => {
      return status;
    },
  });

  return query;
};