import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { FaHeadphones } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa6";
import * as _ from "lodash";
import { useTranslation } from 'react-i18next';
import i18next from '@/i18n';
import { US, ES } from 'country-flag-icons/react/1x1';
import { useIsMutating } from "@tanstack/react-query";

import { useSessionStore } from "@/_zustand/sessionStore";
import { useLogout } from "@/hooks/useAuth";
import { useSettingStore } from '@/_zustand/settingsStore';

import en from './i18n/en';
import es from './i18n/es';
i18next.addResourceBundle('en', 'lang', en);
i18next.addResourceBundle('es', 'lang', es);

export const HeaderTop = () => {
  const { t } = useTranslation('lang');
  const { user, clearUser } = useSessionStore();
  const [langSelected, setLangSelected] = useState(localStorage.getItem('i18nextLng'));
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const signOut = useLogout();
  const isMutating = useIsMutating({ mutationKey: ['useLogoutMutate']});
  const { setting } = useSettingStore();

  const handleLogout = () => {
    signOut.mutateAsync()
    .then((resp) => { 
      clearUser({});
      localStorage.setItem('token', '');
      toast.success("Logout successful!");
    })
    .catch((error) => {
      toast.error(error) 
    });
  }

  const changeLang = (lang) => {
    i18next.changeLanguage(lang);
    setLangSelected(lang);
    setDropDownOpen(false);
  };
  
  return (
    <div className="h-10 text-white bg-blue-500 max-lg:px-5 max-lg:h-16 max-[573px]:px-0">
      <div className="flex justify-between h-full max-lg:flex-col max-lg:justify-center max-lg:items-center max-w-screen-2xl mx-auto px-12 max-[573px]:px-0">
        <ul className="flex items-center h-full gap-x-5 max-[370px]:text-sm max-[370px]:gap-x-2">
          <li className="flex items-center gap-x-2 font-semibold">
            <FaHeadphones className="text-white" />
            <span>{(_.size(setting) > 0) && setting.phone}</span>
          </li>
          <li className="flex items-center gap-x-2 font-semibold">
            <FaRegEnvelope className="text-white text-xl" />
            <span>{(_.size(setting) > 0) && setting.email}</span>
          </li>
        </ul>
        <ul className="flex items-center gap-x-5 h-full max-[370px]:text-sm max-[370px]:gap-x-2 font-semibold">
          <li className="flex items-center gap-x-2">
            <div className=" min-h-screen flex items-center justify-center">
              <div className="relative inline-block text-left">
                <button id="dropdown-button" className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 rounded-md shadow-sm"
                  onClick={() => setDropDownOpen(!dropDownOpen)}
                >
                  <div className="avatar">
                    <div className="ring-primary ring-offset-base-100 w-5 rounded-full">
                      {langSelected == 'en' ? <US className="flag-icon1"/> : <ES className="flag-icon1"/>}
                    </div>
                  </div>
                </button>
                <div id="dropdown-menu" className={`${(dropDownOpen) ? '' : 'hidden'} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
                  <div className="py-2 p-2" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                    <div className="flex rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer" role="menuitem"
                      onClick={() => changeLang('es')}
                    >
                      <div className="avatar" >
                        <div className="ring-primary ring-offset-base-100 w-5 rounded-full">
                          <ES className="flag-icon1"/>
                        </div>
                        <p>&nbsp; {t('Spanish')}</p>
                      </div>
                    </div>
                    <div className="flex rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer" role="menuitem"
                      onClick={() => changeLang('en')}
                    >
                      <div className="avatar" >
                        <div className="ring-primary ring-offset-base-100 w-5 rounded-full">
                          <US className="flag-icon1"/>
                        </div>
                        <p>&nbsp; {t('English')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {
            (_.size(user) == 0) ? 
            ( 
              <>
                <li className="flex items-center">
                  <Link to="/login" className="flex items-center gap-x-2 font-semibold">
                    <FaRegUser className="text-white" />
                    <span>{t('Login')}</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link to="/register" className="flex items-center gap-x-2 font-semibold">
                    <FaRegUser className="text-white" />
                    <span>{t('Register')}</span>
                  </Link>
                </li>
              </>
            ) :  
            (
            <>
              <span className="ml-10 text-base">{`${user.firstname} ${user.lastname}`}</span>
              <li className="flex items-center">
                <button onClick={() => handleLogout()} className="flex items-center gap-x-2 font-semibold" disabled={isMutating}>
                  <FaRegUser className="text-white" />
                  <span>{t('Log out')}</span>
                  {
                    (isMutating) ? <span className="loading loading-spinner ml-2"></span> : <></>
                  }
                </button>
              </li>
              </>
            )
          }
        </ul>
      </div>
    </div>
  )
}
