import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from '@/i18n';

import logo from "@/assets/images/gassapp/logo1.png";

import en from './i18n/en';
import es from './i18n/es';
i18next.addResourceBundle('en', 'lang', en);
i18next.addResourceBundle('es', 'lang', es);

export const Footer = () => {
  const { t } = useTranslation('lang');

  const navigation = {
    sale: [
      { name: t('Discounts'), href: "#" },
      { name: t('News'), href: "#" },
      { name: t('Register Discounts'), href: "#" },
    ],
    about: [
      { name: t('About GassApp'), href: "#" },
      { name: t('Work With Us'), href: "#" },
      { name: t('Company Profile'), href: "#" },
    ],
    buy: [
      { name: t('GassApp Loyalty Card'), href: "#" },
      { name: t('Terms Of Use'), href: "#" },
      { name: t('Privacy Policy'), href: "#" },
      { name: t('Complaints'), href: "#" },
      { name: t('Partners'), href: "#" },
    ],
    help: [
      { name: t('Contact'), href: "#" },
      { name: t('How to Buy at GassApp'), href: "#" },
      { name: t('FAQ'), href: "#" },
    ],
  };

  return (
    <footer className="bg-white print:hidden" aria-labelledby="footer-heading">
      <div>
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-screen-2xl px-6 lg:px-8 pt-24 pb-14">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">

            <img src={logo} alt="GassApp logo" width={250} height={250} className="h-auto w-auto"/>

            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-lg font-bold leading-6 text-blue-600">
                    {t('Sale')}
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.sale.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-sm leading-6 text-black hover:text-gray-700">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-base font-bold leading-6 text-blue-600">
                    {t('About Us')}
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.about.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-sm leading-6 text-black hover:text-gray-700" >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-bold leading-6 text-blue-600">
                    {t('Buying')}
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.buy.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-sm leading-6 text-black hover:text-gray-700" >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-base font-bold leading-6 text-blue-600">
                    {t('Support')}
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.help.map((item) => (
                      <li key={item.name}>
                        <Link to={item.href} className="text-sm leading-6 text-black hover:text-gray-700" >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};