import { useEffect } from 'react';
import { isExpired, decodeToken } from "react-jwt";
import { useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import toast from "react-hot-toast";

import { useSessionStore } from "@/_zustand/sessionStore";

export const useCheckAuth = () => {
  const { clearUser, sessionStatus } = useSessionStore();
  const { pathname } = useLocation();

  const validateToken = async() => {
    const tokenStorage = await localStorage.getItem('token');

    if (!tokenStorage){
      localStorage.removeItem('token');
      clearUser();
      return sessionStatus;
    }

    const isMyTokenExpired = isExpired(tokenStorage);

    if (isMyTokenExpired){
      localStorage.removeItem('token');
      clearUser();
      return sessionStatus;
    }

    return sessionStatus;
  };

  useEffect(() => {
    validateToken();
  }, [pathname]);

  return sessionStatus;
}