import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useSessionStore = create()(
  persist(
    (set) => ({
      user: {},
      sessionStatus: "unauthenticated",
      setUser: (user) => {
        set((state) => {
          return {
            user: user,
            sessionStatus: "authenticated",
          };
        });
      },
      updateuser: (user) => {
        set((state) => {
          return {
            user: user,
          };
        });
      },
      clearUser: () => {
        set((state) => {
          return {
            user: {},
            sessionStatus: "unauthenticated",
          };
        });
      },
    }),
    {
      name: "session-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
