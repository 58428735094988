const locale = {
  "Login": "Login",
  "Register": "Register",
  "Log out": "Log out",
  "English": "English",
  "Spanish": "Spanish",
  "Type Here": "Type Here",
  "Search": "Search",
  "Buy Now": "Buy Now",
  "Shop Now": "Shop Now",
  "Discounts": "Discounts",
  "News": "News",
  "Register Discounts": "Register Discounts",
  "About GassApp": "About GassApp",
  "Work With Us": "Work With Us",
  "Company Profile": "Company Profile",
  "GassApp Loyalty Card": "GassApp Loyalty Card",
  "Terms Of Use": "Terms Of Use",
  "Privacy Policy": "Privacy Policy",
  "Complaints": "Complaints",
  "Partners": "Partners",
  "Contact": "Contact",
  "How to Buy at GassApp": "How to Buy at GassApp",
  "FAQ": "FAQ",
  "Sale": "Sale",
  "About Us": "About Us",
  "Buying": "Buying",
  "Support": "Support",
};

export default locale;
