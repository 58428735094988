const locale = {
  "Login": "Iniciar sesión",
  "Register": "Registrarse",
  "Log out": "Cerrar sesión",
  "English": "Inglés",
  "Spanish": "Español",
  "Type Here": "Escribe aquí",
  "Search": "Buscar",
  "Buy Now": "Comprar ahora",
  "Shop Now": "Comprar ahora",
  "Discounts": "Descuentos",
  "News": "Noticias",
  "Register Discounts": "Descuentos de registro",
  "About GassApp": "Sobre GassApp",
  "Work With Us": "Trabaja con nosotros",
  "Company Profile": "Perfil de la empresa",
  "GassApp Loyalty Card": "Tarjeta de fidelidad de GassApp",
  "Terms Of Use": "Términos de uso",
  "Privacy Policy": "Política de privacidad",
  "Complaints": "Quejas",
  "Partners": "Socios",
  "Contact": "Contacto",
  "How to Buy at GassApp": "Cómo comprar en GassApp",
  "FAQ": "Preguntas frecuentes",
  "Sale": "Venta",
  "About Us": "Sobre nosotros",
  "Buying": "Comprar",
  "Support": "Soporte",

};

export default locale;
