import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";

import { HeaderTop } from "./HeaderTop";
import { SearchInput }  from "./SearchInput";
import { CartElement }  from "./CartElement";
import { HeartElement }  from "./HeartElement";
import { useWishlistStore } from "@/_zustand/wishlistStore";
import { useSessionStore } from "@/_zustand/sessionStore";
import { useWishlistByUserId } from "@/hooks/useWishlist";
import { useSettingStore } from '@/_zustand/settingsStore';
import { getEnvVariables } from "@/constants/getEnvVariables";


import logo from "@/assets/images/gassapp/logo1.png";

export const Header = () => {
  const { setWishlist, wishQuantity } = useWishlistStore();
  const { user } = useSessionStore();
  const getWishlist = useWishlistByUserId();
  const { setting } = useSettingStore();
  const { FILES_URL, DOMAIN } = getEnvVariables();

  const getWishlistByUserId = async () => {
    if(_.size(user) == 0){
      setWishlist([]);
      return;
    }

    getWishlist.mutateAsync(user.id)
    .then((resp) => {;
      setWishlist(resp);
    })
    .catch((error) => {
      toast.error(error) 
    });
  };

  useEffect(() => {
    getWishlistByUserId();
  }, [user]);

  return (
    <header className="bg-white print:hidden">
      <HeaderTop />
      <div className="h-32 bg-white flex items-center justify-between px-16 max-[1320px]:px-16 max-md:px-6 max-lg:flex-col max-lg:gap-y-7 max-lg:justify-center max-lg:h-60 max-w-screen-2xl mx-auto">
        <Link to="/">
          <img src={(_.size(setting.logo_image) > 0) ? `${FILES_URL}/${DOMAIN}/${setting.logo_image.path}` : ''} width={300} height={300} alt="Ecommerce" className="relative right-5 max-[1023px]:w-56" />
        </Link>
        <SearchInput />
        <div className="flex gap-x-10">
          <HeartElement />
          <CartElement />
        </div>
      </div>
    </header>
  );
};