import React from 'react';
import { Link } from 'react-router-dom';
import { FaHeart } from "react-icons/fa6";

import { useWishlistStore } from "@/_zustand/wishlistStore";

export const HeartElement = () => {
  const { wishQuantity } = useWishlistStore();

  return (
    <div className="relative">
      {
        (wishQuantity > 0) ? 
        (
          <Link to="/wishlist">
          <FaHeart className="text-2xl text-black" />
          <span className=" w-6 h-6 font-bold bg-blue-600 text-white rounded-full flex justify-center items-center absolute top-[-17px] right-[-22px]">
            { wishQuantity }
          </span>
        </Link>
        ): 
        (
          <Link to="#">
            <FaHeart className="text-2xl text-black" />
            <span className=" w-6 h-6 font-bold bg-blue-600 text-white rounded-full flex justify-center items-center absolute top-[-17px] right-[-22px]">
              { wishQuantity }
            </span>
          </Link>
        )
      }
      
    </div>
  );
}
