import React, { lazy, useState, useEffect,useEffectEvent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useFavicon } from "@uidotdev/usehooks";

import { App } from './layouts/App';
import { Loader } from './layouts/layoutcomponents/loader';
import { RouterData } from './routes/routingdata';

import './index.css';
import 'svgmap/dist/svgMap.min.css';
import 'react-phone-input-2/lib/style.css'
import favicomImg from './assets/images/gassapp/icon.png'; 

import './i18n';
import './Init';

const Error404 = lazy(() => import('./pages/error404/error404'));

function Index() {
  //const [favicon, setFavicon] = useState("https://ui.dev/favicon/favicon-32x32.png");

  useFavicon(favicomImg);

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        {/* Routes Dashboard */}
        {RouterData.map((idx) => (
          <Route path={`/`} element={<App />} key={Math.random()}>
            <Route path={idx.path} element={idx.element} />
            <Route path={`/*`} element={<Error404 />} />
          </Route>
        ))};
      </Routes>
    </React.Suspense>
  )
}

export default Index
