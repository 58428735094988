import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import i18next from '@/i18n';

import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'lang', en);
i18next.addResourceBundle('es', 'lang', es);

export const SearchInput = () => {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const searchProducts = (e) => {
    e.preventDefault();
    navigate(`/search?search=${searchInput}`);
    setSearchInput("");
  };

  return (
    <form className="flex w-full justify-center" onSubmit={searchProducts}>
      <input className="bg-gray-50 input input-bordered w-[70%] rounded-r-none outline-none focus:outline-none max-sm:w-full" 
        type="text" placeholder={t('Type Here')} value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <button type="submit" className="btn bg-blue-500 text-white rounded-l-none rounded-r-xl hover:bg-blue-600">
        {t('Search')}
      </button>
    </form>
  );
}
