import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useProductStore = create()(
  persist(
    (set) => ({
      products: [],
      allQuantity: 0,
      total: 0,
      taxes: 0,
      payment: {
        address: '',
        payment: '',
        name: '',
        lastname: '',
        phone: '',
        email: '',
        buyOrder: '',
        orderNotice: '',
      },
      payStatusOneClick: false,
      addToCart: (newProduct) => {
        set((state) => {
          const cartItem = state.products.find(
            (item) => item.id === newProduct.id
          );
          if (!cartItem) {
            return { products: [...state.products, newProduct] };
          } else {
            state.products.map((product) => {
              if (product.id === cartItem.id) {
                product.amount = newProduct.amount;
              }
            });
          }
          return { products: [...state.products] };
        });
      },
      clearCart: () => {
        set((state) => {
          return {
            products: [],
            allQuantity: 0,
            total: 0,
            taxes: 0,
            payment: {
              address: '',
              payment: '',
              name: '',
              lastname: '',
              phone: '',
              buyOrder: '',
              orderNotice: '',
            },
            payStatusOneClick: false,
          };
        });
      },
      removeFromCart: (id) => {
        set((state) => {
          state.products = state.products.filter(
            (product) => product.id !== id
          );
          return { products: state.products };
        });
      },
      calculateTotals: () => {
        set((state) => {
          let amount = 0;
          let total = 0;
          let taxes = 0;
          state.products.forEach((item) => {
            taxes += (item.taxes * item.amount);
            amount += item.amount;
            total += item.amount * item.price + taxes;
          });

          return {
            products: state.products,
            allQuantity: amount,
            total: total,
            taxes: taxes,
          };
        });
      },
      updateCartAmount: (id, amount) => {
        set((state) => {
          const cartItem = state.products.find((item) => item.id === id);

          if (!cartItem) {
            return { products: [...state.products] };
          } else {
            state.products.map((product) => {
              if (product.id === cartItem.id) {
                product.amount = amount;
              }
            });
          }

          return { products: [...state.products] };
        });
      },
      setPayment: (payment) => {
        set((state) => {
          return {
            payment: payment,
          };
        });
      },
      setPayStatus: (payment) => {
        set((state) => {
          return {
            payStatusOneClick: payment,
          };
        });
      },
    }),
    {
      name: "products-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage', sessionStorage is used
    }
  )
);
