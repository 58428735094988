import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";

import { useDomainStore } from "@/_zustand/domainStore";

export const NotificationProvider = ({ children }) => {
  const { setUrlApi, setDomain } = useDomainStore();

  useEffect(() => {
    setDomain(getDomain());
    setUrlApi(getUrlApi());
  }, []);

  const getUrlApi = () =>{
    const { protocol, hostname, pathname } = window.location;

    let baseURL = '';
    let domain = hostname;

    if(domain == '127.0.0.1' || domain == 'localhost'){
      domain = 'task-manager.localhost';
    }

    baseURL = `${protocol}//api.${domain}/api`;
    return baseURL;
  }

  const getDomain = () =>{
    let domain = window.location.hostname.split('.');
    return domain[0];
  }


  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            fontSize: "17px",
          },
        }}
      />
      {children}
    </>
  );
};
